import { REQUEST_METHODS } from "../Configs/RequestMethods";
import {
  apiHandlerWithoutFromData,
  apiHandlerWithFromData,
} from "../Utils/ApiHandler";


export const getAllManageUserApi = async (currentPage, limit) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/all-users?page=${currentPage}&limit=${limit}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllManageUserApiWithsearch = async (search, currentPage, limit) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/all-users?page=${currentPage}&limit=${limit}&search=${search}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getActiveUserApi = async (page, limit) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/active?page=${page}&limit=${limit}`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
}
export const getActiveUserApiWthSearch = async (search, page, limit) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/active?page=${page}&limit=${limit}&search=${search}`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
}

export const getBannedUserApi = async (page, limit) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/banned?page=${page}&limit=${limit}`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
}

export const getBannedUserApiWithSearch = async (search, page, limit) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/banned?page=${page}&limit=${limit}&search=${search}`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
}



export const getStatusChangeUserApi = async (formData, _id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.POST,
      `api/v1/admins/users/banned-status/${_id}`,
      formData
    );
    return response;
  } catch (error) {
    return error;
  }
};



export const getAllCategoryApi = async (page) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/categories?page=${page}&limit=10`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createCategoryApi = async (data) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.POST,
      "api/v1/admins/categories/store",
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCategoryApi = async (id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.DELETE,
      `api/v1/admins/categories/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getSingleCategoryApi = async (id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/categories/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCategoryApi = async (id, data) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.PATCH,
      `api/v1/admins/categories/update/${id}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCategoryImageApi = async (id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.DELETE,
      `api/v1/admins/categories/removefile/${id}?key=image`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllGameApi = async (page) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/games?page=${page}&limit=10`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllGameApiWithSearch = async (search, page) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/games?search=${search}&page=${page}&limit=10`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};;

export const createGameApi = async (body) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.POST,
      `api/v1/admins/games/create`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deteleGameApi = async (id) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.DELETE,
      `api/v1/admins/games/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getSingleGameApi = async (id) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/games/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateGameApi = async (id, data) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.PATCH,
      `api/v1/admins/games/${id}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteFileApi = async (id, type) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.DELETE,
      `api/v1/admins/games/removeFile/${id}?key=${type}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllSocialApi = async () => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/socialmedia`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createLinkApi = async (body) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.POST,
      `api/v1/admins/socialmedia/store`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deteleLinkApi = async (id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.DELETE,
      `api/v1/admins/socialmedia/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getSingleLinkApi = async (id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/socialmedia/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateSocialApi = async (id, data) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.PATCH,
      `api/v1/admins/socialmedia/update/${id}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllBannerApi = async (page) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/banner?page=${page}&limit=10`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deteleBannerApi = async (id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.DELETE,
      `api/v1/admins/banner/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deteleBannerImageApi = async (id, type) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.DELETE,
      `api/v1/admins/banner/removefile/${id}?key=${type}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createBannerApi = async (body) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.POST,
      `api/v1/admins/banner/store`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getSingleBannerApi = async (id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/banner/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateBannerApi = async (id, data) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.PATCH,
      `api/v1/admins/banner/update/${id}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllUserContactApi = async (page) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/contact?page=${page}&limit=10`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const zipUpload = async (body, id) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.PATCH,
      `api/v1/admins/games/updatewbgl/${id}`,
      body,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const GetUserTrackDetails = async (id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/user-details/${id}`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const GetCategoryTrackDetails = async (id, page) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/category/${id}?page=${page}&limit=20`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const GetCategoryTrackDetailsWithSearch = async (id, search, page) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/category/${id}?page=${page}&limit=20&serch=${search}`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const GetGameTrackDetails = async (id, sort, page) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/game/${id}?sort=${sort}&page=${page}&limit=10`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const GetGameTrackDetailsWithSearch = async (id, search, field, sort, page) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/game/${id}?page=${page}&search=${search}&field=${field}&sort=${sort}&limit=10`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const GetUserLoginDetails = async (id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/users/loginhistory/${id}`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTop10User = async () => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/dashboard/top-user`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
}
export const getTop10Game = async () => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/dashboard/top-palygame`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
}
export const getDashBoardCount = async () => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/dashboard`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
}
export const getBrowserCount = async () => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/dashboard/browser-counting`,
      {},
    );
    return response;
  } catch (error) {
    return error;
  }
}

// FAQ API Start
export const getAllFAQApi = async (id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/faq/get-all/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const addFAQApi = async (body) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.POST,
      `api/v1/admins/faq/store`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateFAQApi = async (id, body) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.PATCH,
      `api/v1/admins/faq/update/${id}`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getOneFAQApi = async (id) => {
  try {
    const response = await apiHandlerWithoutFromData(
      REQUEST_METHODS.GET,
      `api/v1/admins/faq/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deteleFAQApi = async (id) => {
  try {
    const response = await apiHandlerWithFromData(
      REQUEST_METHODS.DELETE,
      `api/v1/admins/faq/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};