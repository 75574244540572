import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ROUTES from "./Configs/Routes";
import ContextState from "./Components/Context/ContextState";
import Layout from "./Components/Dashboard/Layout";
import "bootstrap/dist/css/bootstrap.min.css";

// admin
import FrontendLogin from "./Components/Auth/UserAuth/FrontendLogin";
import FrontendSignup from "./Components/Auth/UserAuth/FrontendSignup";
import ForgotPassword from "./Components/Auth/UserAuth/ForgotPassword";
import AdminDashboard from "./Components/Dashboard/AdminDashboard";
import ResetPassword from "./Components/Auth/UserAuth/ResetPassword";
import Login from "./Components/Auth/Login";
import AdminForgetPassword from "./Components/Auth/AdminForgetPassoword";
import Game from "./Components/Dashboard/Game/Index";
import AddGame from "./Components/Dashboard/Game/AddGame";
import Category from "./Components/Dashboard/Category/Index";
import AddCategory from "./Components/Dashboard/Category/AddCategory";
import EditCategory from "./Components/Dashboard/Category/EditCategory";
import PlayGame from "./Components/User/Components/PlayGame";
import EditGame from "./Components/Dashboard/Game/EditGame";
import Categorywise from "./Components/User/Components/Categorywise";
import AddSocial from "./Components/Dashboard/Social/AddSocial";
import EditSocial from "./Components/Dashboard/Social/EditSocial";
import Social from "./Components/Dashboard/Social/Index";
import Contact_user, {
  UserConatct,
} from "./Components/Dashboard/Contact/UserConatct";

import ManageUser from "./Components/Dashboard/ManageUser/Index"

import Banner from "./Components/Dashboard/Banner/Index";
import AddBanner from "./Components/Dashboard/Banner/AddBanner";
import EditBanner from "./Components/Dashboard/Banner/EditBanner";

//user
import UserLayout from "./Components/User/UserLayout";
import { Home } from "./Components/User/Home";
import { About } from "./Components/User/Components/About";
import { PrivacyPolicy } from "./Components/User/Components/PrivacyPolicy";
import { Contact } from "./Components/User/Contact";
import { TermsConditions } from "./Components/User/Components/TermsConditions";
import { CoociePolicy } from "./Components/User/Components/CoociePolicy";
import { DisclaimerPolicy } from "./Components/User/Components/DisclaimerPolicy";
import { PageNotFound } from "./Components/PageNotFound";
import AuthLayout from "./Components/User/AuthLayout";
import UserLogin from "./Components/UserLogin";
import UserOtp from "./Components/UserOtp";
import UserSignup from "./Components/UserSignup";
import ActiveUser from "./Components/Dashboard/ManageUser/ActiveUser";
import BannedUser from "./Components/Dashboard/ManageUser/BannedUser";
import Index from "./Components/Dashboard/ManageUser/Index";
import OtpLessModal from "./Components/User/Components/OtpLessModal";
import UserDetail from "./Components/Dashboard/ManageUser/UserDetail";
import CategoryTrack from "./Components/Dashboard/ManageUser/CategoryTrack";
import GameTrack from "./Components/Dashboard/ManageUser/GameTrack";
import LoginHistory from "./Components/Dashboard/ManageUser/LoginHistory";
import ReactGA from "react-ga"
import FAQ from "./Components/Dashboard/Game/FAQ/FAQ";

const TRACKING_ID = "G-GDDYXBJD2Q"

ReactGA.initialize(TRACKING_ID)

function App() {
  return (
    <BrowserRouter>
      <ContextState>
        <Routes>
          {/* <Route path={ROUTES.USER} element={<FrontendLogin />} /> */}
          <Route path={ROUTES.USER_SIGNUP} element={<FrontendSignup />} />
          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
          <Route element={<Layout />}>
            <Route path={ROUTES.ADMIN_DASHBOARD} element={<AdminDashboard />} />
            <Route path={ROUTES.GAME.LIST} element={<Game />} />
            <Route path={ROUTES.GAME.ADD} element={<AddGame />} />
            <Route path={ROUTES.GAME.UPDATE_ID} element={<EditGame />} />
            <Route path={ROUTES.CATEGORY.LIST} element={<Category />} />
            <Route path={ROUTES.CATEGORY.ADD} element={<AddCategory />} />
            <Route path={ROUTES.CATEGORY.UPDATE_ID} element={<EditCategory />} />

            <Route path={ROUTES.FAQ.LIST_id} element={<FAQ />} />

            <Route path={ROUTES.MANAGE_USER.LIST} element={<Index />} />
            <Route path={ROUTES.MANAGE_USER.ACTIVE} element={<ActiveUser />} />
            <Route path={ROUTES.MANAGE_USER.BANNED} element={<BannedUser />} />
            <Route path={ROUTES.MANAGE_USER.USER_DETAIL_ID} element={<UserDetail />} />
            <Route path={ROUTES.TRECK_EVENT.CATEGORY_LIST_ID} element={<CategoryTrack />} />
            <Route path={ROUTES.TRECK_EVENT.GAME_LIST_ID} element={<GameTrack />} />
            <Route path={ROUTES.TRECK_EVENT.LOGIN_LIST_ID} element={<LoginHistory />} />

            <Route path={ROUTES.SOCIAL.LIST} element={<Social />} />
            <Route path={ROUTES.SOCIAL.ADD} element={<AddSocial />} />
            <Route path={ROUTES.SOCIAL.UPDATE_ID} element={<EditSocial />} />

            <Route path={ROUTES.BANNER.LIST} element={<Banner />} />
            <Route path={ROUTES.BANNER.ADD} element={<AddBanner />} />
            <Route path={ROUTES.BANNER.UPDATE_ID} element={<EditBanner />} />
            <Route path={ROUTES.USER_CONTACT} element={<UserConatct />} />
          </Route>
          <Route path={ROUTES.ADMIN_LOGIN} element={<Login />} />
          <Route
            path={ROUTES.ADMIN_FORGET_PASSWORD}
            element={<AdminForgetPassword />}
          />
          <Route path={ROUTES.USER} element={<UserLayout />}>
            <Route path={ROUTES.USER} element={<Home />} />
            <Route
              path={ROUTES.GAME_CATEGORY.LIST_ID}
              element={<Categorywise />}
            />
            <Route path={ROUTES.PLAY_GAME.LIST_ID} element={<PlayGame />} />
            <Route path={ROUTES.ABOUT} element={<About />} />
            <Route path={ROUTES.USER_PRIVACY} element={<PrivacyPolicy />} />
            <Route path={ROUTES.COOKIE_POLICY} element={<CoociePolicy />} />

            <Route
              path={ROUTES.DISCLAIMER_POLICY}
              element={<DisclaimerPolicy />}
            />

            <Route
              path={ROUTES.TERMS_CONDITION}
              element={<TermsConditions />}
            />
            <Route path={ROUTES.CONTACT} element={<Contact />} />
          </Route>

          <Route path={ROUTES.AUTH_USER} element={<AuthLayout />}>
            <Route path={ROUTES.AUTH_LOGIN} element={<UserLogin />} />
            <Route path={ROUTES.AUTH_OTP} element={<UserOtp />} />
            <Route path={ROUTES.AUTH_SIGNIN} element={<UserSignup />} />
            <Route path={ROUTES.OTPLESS} element={<OtpLessModal />} />
          </Route>

          <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
        </Routes>
      </ContextState>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
