import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Link, useParams, useNavigate } from 'react-router-dom'
import ROUTES from '../../../Configs/Routes'
import { GetUserTrackDetails } from '../../../APIs/AdminAll_api'
import GameTrack from "./GameTrack"

function UserDetail() {
	const { id } = useParams()
	const navigate = useNavigate()
	const [laoding, setLoading] = useState(false)
	const [data, setData] = useState("")

	const getData = async () => {
		setLoading(true);
		const data = await GetUserTrackDetails(id);
		if (data.status === 200) {
			setLoading(false);
			setData(data.data.data)
		}
	}

	useEffect(() => {
		getData()
	}, [])


	return (
		<div>
			<div className='d-flex justify-content-between'>
				<h4 className="content-title mb-0 my-auto">User Details</h4>
				<Button className="cancelBtn" onClick={() => { navigate(ROUTES.MANAGE_USER.LIST) }}>Back</Button>
			</div>

			<div>
				<Row className='gameRow userRow'>
					<Col sm={6} xxl={3}>
						<div className='gameCard'>
							<div className='d-flex flex-wrap align-items-center'>
								<span className='gameCardIcon'>
									<i className='las la-gamepad'></i>
								</span>
								<div className='gameCardText'>
									<p>Total Game Played</p>
									<span>{data.gameLogCount}</span>
								</div>
								{/* <Link to={ROUTES.TRECK_EVENT.GAME_LIST + id}>View All</Link> */}
							</div>
						</div>
					</Col>

					<Col sm={6} xxl={3}>
						<div className='gameCard'>
							<div className='d-flex flex-wrap align-items-center'>
								<span className='gameCardIcon'>
									<i className='las la-gamepad'></i>
								</span>
								<div className='gameCardText'>
									<p>Total Game Search</p>
									<span>{data.searchLogCount}</span>
								</div>
								{/* {id && <Link to={ROUTES.TRECK_EVENT.GAME_LIST + id}>View All</Link>} */}
							</div>
						</div>
					</Col>
					<Col sm={6} xxl={3}>
						<div className='gameCard'>
							<div className='d-flex flex-wrap align-items-center'>
								<span className='gameCardIcon'>
									<i className='las la-gamepad'></i>
								</span>
								<div className='gameCardText'>
									<p>Total Like Count</p>
									<span>{data.likeLogCount}</span>
								</div>
								{/* {id && <Link to={ROUTES.TRECK_EVENT.GAME_LIST + id}>View All</Link>} */}
							</div>
						</div>
					</Col>
					<Col sm={6} xxl={3}>
						<div className='gameCard'>
							<div className='d-flex flex-wrap align-items-center'>
								<span className='gameCardIcon'>
									<i className='las la-gamepad'></i>
								</span>
								<div className='gameCardText'>
									<p>Total Dislike Count</p>
									<span>{data.disLikeLogCount}</span>
								</div>
								{/* {id && <Link to={ROUTES.TRECK_EVENT.GAME_LIST + id}>View All</Link>} */}
							</div>
						</div>
					</Col>
					<Col sm={6} xxl={3}>
						<div className='gameCard'>
							<div className='d-flex flex-wrap align-items-center'>
								<span className='gameCardIcon'>
									<i className='las la-gamepad'></i>
								</span>
								<div className='gameCardText'>
									<p>Total Full Screen Click</p>
									<span>{data.fulscreenLogCount}</span>
								</div>
								{/* {id && <Link to={ROUTES.TRECK_EVENT.GAME_LIST + id}>View All</Link>} */}
							</div>
						</div>
					</Col>
					<Col sm={6} xxl={3}>
						<div className='gameCard'>
							<div className='d-flex flex-wrap align-items-center'>
								<span className='gameCardIcon'>
									<i className='las la-gamepad'></i>
								</span>
								<div className='gameCardText'>
									<p>Total Category Check</p>
									<span>{data.categoryLogCount}</span>
								</div>
								<Link to={ROUTES.TRECK_EVENT.CATEGORY_LIST + id}>View All</Link>
							</div>
						</div>
					</Col>
					<Col sm={6} xxl={3}>
						<div className='gameCard'>
							<div className='d-flex flex-wrap align-items-center'>
								<span className='gameCardIcon'>
									<i className='las la-gamepad'></i>
								</span>
								<div className='gameCardText'>
									<p>Total Login Count </p>
									<span>{data.loginHistory}</span>
								</div>
								{id && <Link to={ROUTES.TRECK_EVENT.LOGIN_LIST + id}>View All</Link>}
							</div>
						</div>
					</Col>
				</Row >
				<GameTrack />
			</div>
		</div>
	)
}

export default UserDetail