import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getOneFAQApi, updateFAQApi } from '../../../../APIs/AdminAll_api';

const EditFAQ = ({ SetEditFaqModalStatus, getAllFAQ, faqId }) => {

  const [loading, setLoading] = useState(false)
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')

  const getOneFAQ = async () => {
    setLoading(true)
    const data = await getOneFAQApi(faqId)
    if (data.status === 200) {
      setQuestion(data.data.data.question)
      setAnswer(data.data.data.answer)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (faqId) {
      getOneFAQ()
    }
  }, [])

  const handleEditFAQ = async () => {
    setLoading(true)

    const body = {
      question: question,
      answer: answer
    }

    if (question === '') {
      toast.error("Please enter question")
      setLoading(false)
    } else if (answer === '') {
      toast.error("Please enter answer")
      setLoading(false)
    } else {
      const data = await updateFAQApi(faqId, body)
      if (data.status === 200) {
        setLoading(false)
        SetEditFaqModalStatus(false)
        getAllFAQ()
        toast.success(data.data.message);
      } else {
        toast.error(data.response.data.message);
        setLoading(false)
      }
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Edit FAQ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              Question
            <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              type='text'
              as='textarea'
              placeholder='Question'
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              Answer
            <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              type='text'
              as='textarea'
              placeholder='Answer'
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { SetEditFaqModalStatus(false) }}>
          Close
        </Button>
        <Button variant="primary" onClick={() => handleEditFAQ()}>
          {
            loading ?
              <div
                class="spinner-border spinner-border-sm me-2"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div> : "Edit FAQ"
          }
        </Button>
      </Modal.Footer>
    </>
  )
}

export default EditFAQ