import React, { useEffect, useState, useRef, useContext } from "react";
import {
  getSingleGameApi,
  getWithoutRunningGameApi,
  LikeApi,
  disLikeApi,
  TrackGame,
  TrackLike,
  TrackDisLike,
  TrackFullScreen,
  getAllFAQApi,
} from "../../../APIs/UserAll_api";
import { useParams, useNavigate, useLocation, NavLink, Link } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";
import { toast } from "react-toastify";
import MainContext from "../../Context/MainContext";
import { Breadcrumb } from "react-bootstrap";
import { motion } from "framer-motion";

const PlayGame = () => {
  const [game, setGame] = useState([]);
  const [gameDetails, setGameDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [gameList, setGameList] = useState([]);
  const [allFAQ, setAllFAQ] = useState([]);
  const [like, setLike] = useState("");
  const [disLike, setDisLike] = useState("");
  const [fullScreen, setFullScreen] = useState(false);
  const location = useLocation();

  const [dragging, setDragging] = useState(false);
  const [dragStartTime, setDragStartTime] = useState(null);
  const [hasMoved, setHasMoved] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);

  const data = useContext(MainContext);

  const { catId } = useParams();
  const ref = useRef(null);
  const navigate = useNavigate();

  const getAllGame = async () => {
    try {
      setLoading(true);
      const res = await getSingleGameApi(catId);
      if (res.status === 200) {
        setLoading(false);
        setGame(res.data);
        setIsLoaded(false)
        setLike(res.data.likeCount);
        setDisLike(res.data.dislikeCount);
        setGameDetails(res.data.data)
      } else if (res.response.status === 404) {
        setLoading(false);
        navigate(ROUTES.PAGE_NOT_FOUND);
      } else if (res.response.status === 500) {
        setLoading(false);
        navigate(ROUTES.PAGE_NOT_FOUND);
      } else {
        setLoading(false);
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const getAllFAQ = async () => {
    // setLoading(true)
    const res = await getAllFAQApi(gameDetails._id)
    if (res.status === 200) {
      setAllFAQ(res.data.data)
      // setLoading(false)
    }
  }

  useEffect(() => {
    if (gameDetails) {
      setTimeout(() => {
        getAllFAQ()
      }, 1000)
    }
  }, [gameDetails])

  const getWithoutRunningGame = async () => {
    try {
      const res = await getWithoutRunningGameApi(catId);
      if (res.status === 200) {
        setGameList(res.data.data);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getAllGame();
    getWithoutRunningGame();
    let gameGrids = document.getElementsByClassName("showvideo");
    let gameGrid = document.getElementsByClassName("showwebgl");
    // setTimeout(() => {
    //   gameGrids[0]?.classList.add("d-none");
    //   gameGrid[0]?.classList.remove("d-none");
    // }, [6000]);
    // }, [catId, fullScreen]);
  }, [catId]);

  const handleHover = (e) => {
    e.target.play();
  };

  const handleHoverOut = (e) => {
    e.target.currentTime = 0;
    e.target.pause();
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  const handleBack = () => {
    navigate(ROUTES.USER);
  };

  const likeLog = async () => {
    if (localStorage.getItem("gameUserToken")) {
      const body = {
        game_id: game.data._id
      }
      await TrackLike(body)
    }
  }

  const handleLike = async () => {
    let passdata = {
      game_id: game.data._id,
    };
    try {
      const res = await LikeApi(passdata);
      if (res.status === 200) {
        likeLog()
        setLike(res.data.likeCount);
        setDisLike(res.data.dislikeCount);
      }
    } catch (error) {
    }
  };

  const dislikeLog = async () => {
    if (localStorage.getItem("gameUserToken")) {
      const body = {
        game_id: game.data._id
      }
      await TrackDisLike(body)
    }
  }

  const handleDislike = async () => {
    let passdata = {
      game_id: game.data._id,
    };
    try {
      const res = await disLikeApi(passdata);
      if (res.status === 200) {
        dislikeLog()
        setDisLike(res.data.dislikeCount);
        setLike(res.data.likecount);
      }
    } catch (error) {
    }
  };

  const elemRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (width < 576) {
      handleFullScreen();
    }
  }, [width, isLoaded, game]); // Wait for iframe to load

  const handleFullScreen = async () => {
    const elem = elemRef.current;
    if (elem) {
      if (elem.requestFullscreen) {
        await elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        await elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        await elem.msRequestFullscreen();
      }
    }
  };


  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  console.log("isFullscreen", isFullscreen)

  const handleExitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    }
  };

  const handleLog = async () => {
    if (localStorage.getItem("gameUserToken")) {
      const body = {
        game_id: game.data._id
      }
      await TrackFullScreen(body)
    }
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
    });
    return () => {
      document.removeEventListener('fullscreenchange', () => {
      });
    };
  }, []);

  const handleDragStart = () => {
    setDragging(true);
    setDragStartTime(Date.now());
  };

  const handleDrag = () => {
    setHasMoved(true);
  };

  const handleDragStop = (e) => {
    setDragging(false);
    const dragDuration = Date.now() - dragStartTime;
    if (!hasMoved && dragDuration < 200) {
      handleBack(e);
    }
    setHasMoved(false);
    setDragStartTime(null);
  };

  useEffect(() => {
    if (width < 576) {
      handleFullScreen()
    }
  }, [width])

  useEffect(() => {
    if (width <= 1024) {
      handleFullScreen()
    }
  }, [width])

  const handleGame = async (id, name) => {

    data.setGameName(name)
    const decodedParam = decodeURIComponent(name);
    const mergedParam = decodedParam.split(' ').join('').toLowerCase();

    navigate(`${ROUTES.PLAY_GAME.LIST}/${name}`, { state: { game_id: id } })
    if (localStorage.getItem("gameUserToken")) {
      const body = {
        game_id: id
      }
      await TrackGame(body)
    }
  }

  return (
    <div className="position-relative">


      {/* {fullScreen === false ? ( */}
      <>
        {loading ? (
          <div className="text-center" style={{ minHeight: 'calc(100vh - 102px)', verticalAlign: 'middle' }}>
            <div class="spinner-border text-white" style={{ width: "3rem", height: "3rem" }} role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex">
              <div className="play-one d-none d-md-block">
                <div className="playGamesDiv mt-2">
                  {gameList.slice(0, 12).map((game, index) => {
                    return (
                      <div key={index} onClick={() => handleGame(game?._id, game?.slug)}>
                        <div className="gameGridDiv" key={game}>
                          {game.video_file && (
                            <video
                              onMouseEnter={(e) => handleHover(e)}
                              onMouseLeave={(e) => handleHoverOut(e)}
                              className="homeVideo"
                              src={game.video_file}
                              muted
                              loop
                              autoPlay
                              onClick={() => handleGame(game?._id, game?.slug)}
                            ></video>
                          )}
                          <img
                            src={game.image}
                            alt="image"
                            className={game.video_file ? "homeimage" : ""}
                          />
                          <span className="gameName">{game.name}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="play-two">
                <div className="text-center position-relative" ref={elemRef}>
                  <iframe
                    className={`showwebgl ${isFullscreen ? 'iframe-main-full' : 'iframe-main'} `}
                    src={`${process.env.REACT_APP_AWS_URL}${process.env.REACT_APP_S3_FOLDER}/${game?.data?.webgl_zip}/index.html`}
                    onLoad={() => setIsLoaded(true)}
                  ></iframe>

                  {(width < 576 && location.pathname.includes(ROUTES.PLAY_GAME.LIST)) ? (
                    // <>
                    //   <Draggable
                    //     // axis="x"
                    //     bounds="body"
                    //     onStart={handleDragStart}
                    //     onDrag={handleDrag}
                    //     onStop={handleDragStop}
                    //     enableUserSelectHack={false}
                    //   >
                    //     <button className="back-span position-absolute" ><i className="fa-solid fa-backward"></i></button>
                    //   </Draggable>
                    // </>
                    <motion.button
                      drag="y" // Enable vertical dragging only
                      dragConstraints={{ top: 0, bottom: 650 }} // Set drag limits
                      whileTap={{ scale: 0.9 }} // Small effect on tap
                      className="back-span"
                      onClick={() => handleBack()}
                      style={{
                        touchAction: "none",
                        cursor: "grab",
                        position: isFullscreen ? "fixed" : "absolute",
                      }}
                    >
                      <i className="fa-solid fa-backward"></i>
                    </motion.button>
                  ) : (
                    ""
                  )}


                </div>
                <div className="game-discription d-none d-sm-block mt-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-start">
                      <p className="game-type text-white mb-0">Name</p>
                      <p className="game-name text-white mb-0">{game.data?.name}</p>
                    </div>
                    <div className="d-flex">
                      <p className="likeIcon me-4 d-flex align-items-center mb-0">
                        <i className={like ? "fa-regular fa-thumbs-up me-2 like-icon" : "fa-regular fa-thumbs-up me-2"} onClick={handleLike}></i>
                        <span>{like}</span>
                      </p>
                      <p className="likeIcon me-4 d-flex align-items-center mb-0">
                        <i className={disLike ? "fa-regular fa-thumbs-down me-2 dislike-icon" : "fa-regular fa-thumbs-down me-2"} onClick={handleDislike}></i>{" "}
                        <span>{disLike}</span>
                        <i class="fa-solid fa-expand ms-4" onClick={() => { handleFullScreen(); handleLog(); }}></i>
                      </p>
                    </div>
                  </div>
                  {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                  <div>
                    <div>
                      <div className="game-description-box">
                        {
                          gameDetails.rating !== '' && gameDetails.rating !== undefined &&
                          <div className="row my-2">
                            <div className="col-3">
                              <p>Rating:</p>
                            </div>
                            <div className="col-9">
                              <p className="text-start"><span className="fw-bolder">{gameDetails.rating}</span> <small>({like} <small>votes</small>)</small></p>
                            </div>
                          </div>
                        }
                        {
                          gameDetails.developer !== '' && gameDetails.developer !== undefined &&
                          <div className="row my-2">
                            <div className="col-3">
                              <p>Developer:</p>
                            </div>
                            <div className="col-9">
                              <p className="text-start active-game-text">{gameDetails.developer}</p>
                            </div>
                          </div>
                        }
                        {
                          gameDetails.released !== '' && gameDetails.released !== undefined &&
                          <div className="row my-2">
                            <div className="col-3">
                              <p>Released:</p>
                            </div>
                            <div className="col-9">
                              <p className="text-start">{gameDetails.released}</p>
                            </div>
                          </div>
                        }
                        {
                          gameDetails.technology !== '' && gameDetails.technology !== undefined &&
                          <div className="row my-2">
                            <div className="col-3">
                              <p>Technology:</p>
                            </div>
                            <div className="col-9">
                              <p className="text-start">{gameDetails.technology}</p>
                            </div>
                          </div>
                        }
                        {
                          gameDetails.platform !== '' && gameDetails.platform !== undefined &&
                          <div className="row my-2">
                            <div className="col-3">
                              <p>Platforms:</p>
                            </div>
                            <div className="col-9">
                              <p className="text-start">{gameDetails.platform}</p>
                            </div>
                          </div>
                        }
                        {
                          gameDetails?.classification?.length > 0 &&
                          <>
                            <div className="row my-2">
                              <div className="col-3">
                                <p>Classification:</p>
                              </div>
                              <div className="col-9">
                                <Breadcrumb className="classification-list">
                                  <Breadcrumb.Item>
                                    <Link to={ROUTES.USER}>
                                      Game
                                    </Link>
                                  </Breadcrumb.Item>
                                  <Breadcrumb.Item to={`/play-category/${gameDetails.category_id.slug}`}>
                                    <Link to={`/play-category/${gameDetails.category_id.slug}`}>
                                      {gameDetails.category_id.name}
                                    </Link>
                                  </Breadcrumb.Item>
                                </Breadcrumb>
                              </div>
                            </div>

                          </>
                        }
                      </div>
                    </div>
                    <hr />
                    {
                      gameDetails.more_info !== '' && gameDetails.more_info !== undefined &&
                      <p className="common-p-tag-size">{gameDetails.more_info}</p>
                    }
                    {
                      gameDetails.how_to_play !== '' && gameDetails.how_to_play !== undefined && gameDetails.how_to_play !== "<p><br></p>" &&
                      <div>
                        <p className="game-heading-text">How to Play {game.data?.name}</p>
                        <p dangerouslySetInnerHTML={{ __html: gameDetails?.how_to_play }} className="common-p-tag-size"></p>
                      </div>
                    }
                    {
                      gameDetails.release_date !== '' && gameDetails.release_date !== undefined && gameDetails.release_date !== "<p><br></p>" &&
                      <div>
                        <p className="game-heading-text">Release Date</p>
                        <p dangerouslySetInnerHTML={{ __html: gameDetails?.release_date }} className="common-p-tag-size"></p>
                      </div>
                    }
                    {
                      gameDetails.platform_describe !== '' && gameDetails.platform_describe !== undefined && gameDetails.platform_describe !== "<p><br></p>" &&
                      <div>
                        <p className="game-heading-text">Platforms</p>

                        <p dangerouslySetInnerHTML={{ __html: gameDetails.platform_describe }} className="common-p-tag-size"></p>
                      </div>
                    }
                    {
                      gameDetails.controls !== '' && gameDetails.controls !== undefined &&
                      <div>
                        <p className="game-heading-text">Controls</p>
                        <p className="common-p-tag-size">{gameDetails.controls}</p>
                      </div>
                    }
                    {
                      allFAQ.length > 0 &&
                      <div>
                        <p className="game-heading-text">FAQ</p>
                        {
                          allFAQ.map((faq, index) => (
                            <div key={index}>
                              <h4 className="my-3">{faq.question}</h4>
                              <p className="my-3 faq-answer">{faq.answer}</p>
                            </div>
                          ))
                        }
                      </div>
                    }
                    <div className="d-flex mt-4">
                      {
                        gameDetails.classification.length > 0 &&
                        gameDetails.classification.map((item) => {
                          const decodedParam = decodeURIComponent(item.name)
                          const mergedParam = decodedParam.split(' ').join('').toLocaleLowerCase()
                          return (
                            <NavLink
                              className="d-flex align-items-center justify-content-between classification-list-image"
                              // to={`/play-category/${mergedParam}`}
                              to={`/play-category/${item.slug}`}
                            >
                              <img src={item.image} height={'30%'} width={'30%'} />
                              <p className="fw-bolder ms-2" style={{ fontSize: '11px' }}>{item.name}</p>
                            </NavLink>
                          )
                        })
                      }
                    </div>


                    {
                      <div className=" mt-4 d-flex flex-wrap gap-3">
                        {
                          gameDetails.tags.length > 0 &&
                          gameDetails.tags.map((item, index) => (
                            <div key={index} className="tag-item px-3 py-2">
                              {item}
                            </div>
                          ))
                        }
                      </div>
                    }
                  </div>
                </div>


              </div>

              <div className="play-one d-none d-xl-block">
                <div className="playGamesDiv mt-2">
                  {gameList.slice(12, 24).map((game, index) => {
                    return (
                      <div key={index} onClick={() => handleGame(game?._id, game?.slug)}>
                        <div className="gameGridDiv" key={game}>
                          {game.video_file && (
                            <video
                              onMouseEnter={(e) => handleHover(e)}
                              onMouseLeave={(e) => handleHoverOut(e)}
                              className="homeVideo"
                              src={game.video_file}
                              muted
                              loop
                              onClick={() => handleGame(game?._id, game?.slug)}
                            ></video>
                          )}
                          <img
                            src={game?.image}
                            alt="image"
                            className={game?.video_file ? "homeimage" : ""}
                          />
                          <span className="gameName">{game?.name}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

          </>
        )}
      </>
      {/* ) : (
        <>
          {loading ? <div className="text-center">
            <div class="spinner-border text-white" style={{ width: "3rem", height: "3rem" }} role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div> : <div className="position-absolute full-screen-div" style={fullScreen ? { width: '100%', height: '100%' } : {}}>
            <iframe
              className="showwebgl frame-full-screen"
              // src={`${process.env.REACT_APP_AWS_URL}webgl_staging/${game?.data?.webgl_zip}/index.html`}
              src={`${process.env.REACT_APP_AWS_URL}${process.env.REACT_APP_S3_FOLDER}/${game?.data?.webgl_zip}/index.html`}
              ref={elemRef}
              onLoad={() => setIsLoaded(true)}
            ></iframe>
            <i className="fa-solid fa-compress ms-4" onClick={handleExitFullScreen}></i>
          </div>}
        </>
      )
      } */}
    </div >
  );
};

export default PlayGame;
