

import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Button } from 'react-bootstrap'
import { getBrowserCount, getDashBoardCount, getTop10Game, getTop10User } from "../../APIs/AdminAll_api";
import moment from "moment";
import Chart from "react-apexcharts";
// import io from "socket.io-client";


// export const SOCKET = io("wss://socket.idleprice.io", { transports: ['websocket'] });


export default function AdminDashboard() {
  const [UserList, setUserList] = useState([])
  const [allGame, setAllGame] = useState([]);
  const [DashBoardCount, setDashBoardCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lable, setLable] = useState([])
  const [chartSeries, setChartSeries] = useState([])

  const getUser = async () => {
    setLoading(true);
    const response = await getTop10User();
    if (response?.status === 200) {
      setUserList(response.data.data)
      setLoading(false)
    } else {
      setLoading(false)
    }
  };




  // const game_data = () => {
  //   SOCKET.emit("get_one_Games", {
  //     game_id: id
  //   });
  //   SOCKET.on("return_get_one_Games", (data) => {
  //     if (data.status === 'success') {
  //       setGameData(data.data)
  //     }
  //   });
  // }

  const getGame = async () => {
    setLoading(true);
    const data = await getTop10Game();
    if (data.status === 200) {
      setLoading(false);
      setAllGame(data.data.data);
    }
  };

  useEffect(() => {
    getUser();
    getGame();
    getDashBoardData();
    getBrowserData();
  }, [])

  const options = {
    labels: lable,
    chart: {
      type: 'donut',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  const getDashBoardData = async () => {
    setLoading(true)
    const response = await getDashBoardCount();
    if (response?.status === 200) {
      setDashBoardCount(response.data.data)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }
  const getBrowserData = async () => {
    setLoading(true)
    const response = await getBrowserCount();
    if (response?.status === 200) {
      setLable(response.data.data.totalBrowserCounting.map((item) => item._id))
      setChartSeries(response.data.data.totalBrowserCounting.map((item) => item.count))
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  return (

    <>
      <div className="container-fluid">
        <div className="dashboard-header">
          <div className="left-content">
            <div>
              <h2 className="main-content-title tx-24 mg-b-1">Hi, Welcome Back!</h2>
            </div>
          </div>

        </div>
        <Row className="row-sm">
          <Col xl={3}  >
            <Card className="sales-card">
              <div className="ps-3 pt-3 pe-3 pb-2 pt-0">
                <div className="pb-0 mt-0">
                  <div className="d-flex">
                    <div>
                      <h4 className="card-amount mb-1">Total Active Users</h4>
                      <p className="mb-0 card-para">{DashBoardCount.totalActiveUsers}</p>
                    </div>
                    <span className="my-auto ms-auto card-span">
                      <i class="fa-solid fa-users"></i>
                    </span>
                  </div>
                </div>
              </div>

            </Card>
          </Col>
          <Col xl={3}  >
            <Card className="today-card">
              <div className="ps-3 pt-3 pe-3 pb-2 pt-0">
                <div className="pb-0 mt-0">
                  <div className="d-flex">
                    <div>
                      <h4 className="card-amount mb-1">Total Active Games</h4>
                      <p className="mb-0 card-para">{DashBoardCount.totalActiveGame}</p>
                    </div>
                    <span className="my-auto ms-auto card-span">
                      <i class="fa-solid fa-gamepad"></i>
                    </span>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xl={3}  >
            <Card className="earn-card">
              <div className="ps-3 pt-3 pe-3 pb-2 pt-0">
                <div className="pb-0 mt-0">
                  <div className="d-flex">
                    <div>
                      <h4 className="card-amount mb-1">Total Active Categories</h4>
                      <p className="mb-0 card-para">{DashBoardCount.totalActiveCategory}</p>
                    </div>
                    <span className="my-auto ms-auto card-span">
                      <i class="fa-solid fa-layer-group"></i>
                    </span>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xl={3}  >
            <Card className="sold-card">
              <div className="ps-3 pt-3 pe-3 pb-2 pt-0">
                <div className="pb-0 mt-0">
                  <div className="d-flex">
                    <div>
                      <h4 className="card-amount mb-1">Live User</h4>
                      <p className="mb-0 card-para">35</p>
                    </div>
                    <span className="my-auto ms-auto card-span">
                      <i class="fa-solid fa-users"></i>
                    </span>
                  </div>
                </div>
              </div>

            </Card>
          </Col>
        </Row>
        <Row className="my-5">
          <div className="dash-table-header">Browser Data</div>
          <Chart
            options={options}
            series={chartSeries}
            type="donut"
            width="50%"
            height={300} />
        </Row>
        <Row>
          <Col xl={6}>
            <Card>
              <Card.Body>
                <div className="dash-table-header">Top 10 User Data</div>
                <div className="table-responsive border-top userlist-table">
                  <Table
                    responsive
                    className="table card-table table-hover table-vcenter text-nowrap mb-3 usersTable"
                  >
                    <thead className="mb-3">
                      <tr className="mb-3 head-color">
                        <th>User Name</th>
                        <th>Phone No.</th>
                        <th>Name</th>
                        <th>Joined At</th>
                        <th className='text-center'>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {!loading && UserList.map((item, index) => {
                        let date = new Date(item.createdAt)
                        let createdDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                        let createdTime = date.getHours() + ':' + date.getMinutes();
                        return (
                          <tr key={index}>
                            <td>{item?.username || "-"}</td>
                            <td>{item?.phone}</td>
                            <td>{item?.first_name || "-"}</td>
                            <td>{createdDate} {createdTime} {date.getHours() > 12 ? 'PM' : 'AM'} <br />
                              {moment(date).fromNow()}</td>
                            <td className='text-center'>{item?.status ? "Inactive" : "Active"}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  {UserList.length === 0 && !loading && (
                    <p className="dataNotFound text-center">Data not found</p>
                  )}
                  {loading ? (
                    <div className="text-center">
                      <div
                        class="spinner-border m-2"
                        style={{ width: "3rem", height: "3rem" }}
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6}>
            <Card>
              <Card.Body>
                <div className="dash-table-header">Top 10 Game Data</div>
                <div className="table-responsive border-top userlist-table">
                  <Table
                    responsive
                    className="table card-table table-hover table-vcenter text-nowrap mb-3 usersTable"
                  >
                    <thead className="mb-3">
                      <tr className="mb-3 head-color">
                        <th>Name</th>
                        <th>Image</th>
                        <th>Game Create At</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {!loading && allGame.map((item, index) => {
                        let date = new Date(item.createdAt)
                        let createdDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                        let createdTime = date.getHours() + ':' + date.getMinutes();
                        return (
                          <tr key={index}>
                            <td>{item?.name || "-"}</td>
                            <td>
                              {item.image && <img
                                src={item.image}
                                alt="game"
                                className="game-image"
                              />}
                            </td>
                            <td>{createdDate} {createdTime} {date.getHours() > 12 ? 'PM' : 'AM'} <br />
                              {moment(date).fromNow()}</td>
                            <td>{item?.status ? "Active" : "Inactive"}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  {allGame.length === 0 && !loading && (
                    <p className="dataNotFound text-center">Data not found</p>
                  )}
                  {loading ? (
                    <div className="text-center">
                      <div
                        class="spinner-border m-2"
                        style={{ width: "3rem", height: "3rem" }}
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div >
    </>
  )
}