import React, { useState, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import "../../assets/Css/FrontendCss/style.css";
import { Container } from "react-bootstrap";
import Footer from "./Footer";
import { useLocation, useParams } from "react-router-dom";
import ROUTES from "../../Configs/Routes";
import Sidebar from "./Sidebar";

const UserLayout = () => {
  const location = useLocation();
  let myRef = useRef();
  const { catId } = useParams();
  const [sidebar, setSidebar] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  useEffect(() => {
    if (width < 1024) {
      setSidebar(false);
    }
  }, [width]);

  useEffect(() => {
    if (myRef.current) {
      myRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [location.pathname]);

  return (
    <div className="user-main">
      {width < 576 &&
        location.pathname === `${ROUTES.PLAY_GAME.LIST}/${catId}` ? (
        <>
          <Outlet />
        </>
      ) : (
        <>
          <Container fluid className="container-fluid nav-bg">
            <Navbar sidebar={sidebar} setSidebar={setSidebar} />
          </Container>
          <div className="d-flex">
            <Sidebar sidebar={sidebar} setSidebar={setSidebar} />
            <div className="home-main" ref={myRef}>
              <Container fluid className="main-height" >
                <Outlet />
              </Container>
              <Footer />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserLayout;
