const ADMIN_LOGIN = "/admin/login";
const ADMIN_FORGET_PASSWORD = "/admin/forgetpassword";
// manage user

const USER = "/";
const USER_SIGNUP = "/signup";
const FORGOT_PASSWORD = "/forgot-password";
const RESET_PASSWORD = "/reset-password";
const ADMIN_DASHBOARD = "/admin/dashboard";
const OTPLESS = "/otpless"

const AUTH_USER = "/"
const AUTH_LOGIN = "/auth-login"
const AUTH_OTP = "/auth-otp"
const AUTH_SIGNIN = "/auth-signup"

const ABOUT = "/about-us";
const USER_PRIVACY = "/privacy-policy";
const TERMS_CONDITION = "/terms-condition";
const COOKIE_POLICY = "/cookie-policy";
const DISCLAIMER_POLICY = "/disclaimer-policy";
const CONTACT = "/contact-us";

const FLOT_CHART = "/admin/dashboard/flotchart";
const MORRIS_CHART = "/admin/dashboard/morrischart";
const CHART_JS = "/admin/dashboard/chartjs";

const PROFILE_PAGE = "/admin/web-apps/userlist/profile";

const PLAY_GAME = {
  LIST: "/play-game",
  LIST_ID: "/play-game/:catId/",
};

const GAME = {
  LIST: "/admin/game",
  ADD: "/admin/add-game",
  UPDATE: "/admin/update-game",
  UPDATE_ID: "/admin/update-game/:id",
};

const FAQ = {
  LIST: '/admin/faq',
  LIST_id: '/admin/faq/:id',
  // ADD: '/admin/add-faq',
  // UPDATE: '/admin/update-faq',
  // UPDATE_ID: '/admin/update-faq/:id',
}

const MANAGE_USER = {
  LIST: "/admin/manage-user",
  ACTIVE: "/admin/active-user",
  BANNED: "/admin/banned-user",
  USER_DETAIL: "/admin/user-detail/",
  USER_DETAIL_ID: "/admin/user-detail/:id",
}

const TRECK_EVENT = {
  CATEGORY_LIST: "/admin/treck-category/",
  CATEGORY_LIST_ID: "/admin/treck-category/:id",
  GAME_LIST: "/admin/treck-game/",
  GAME_LIST_ID: "/admin/treck-game/:id",
  LOGIN_LIST: "/admin/login-history/",
  LOGIN_LIST_ID: "/admin/login-history/:id",
}

const EDIT_MANAGE_USER = {
  LIST: "/admin/user/edit/",
  GET: "/admin/user/edit/:id",
};

const CATEGORY = {
  LIST: "/admin/category",
  ADD: "/admin/add-category",
  UPDATE: "/admin/update-category",
  UPDATE_ID: "/admin/update-category/:id",
};

const SOCIAL = {
  LIST: "/admin/social",
  ADD: "/admin/add-social",
  UPDATE: "/admin/update-social",
  UPDATE_ID: "/admin/update-social/:id",
};
const BANNER = {
  LIST: "/admin/banner",
  ADD: "/admin/add-banner",
  UPDATE: "/admin/update-banner",
  UPDATE_ID: "/admin/update-banner/:id",
};
const GAME_CATEGORY = {
  LIST: "/play-category",
  LIST_ID: "/play-category/:catId",
};

const PAGE_NOT_FOUND = "*"

const USER_CONTACT = "admin/usercontact";
const ROUTES = {
  USER,
  USER_SIGNUP,
  ABOUT,
  TRECK_EVENT,
  OTPLESS,
  USER_PRIVACY,
  TERMS_CONDITION,
  COOKIE_POLICY,
  DISCLAIMER_POLICY,
  CONTACT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ADMIN_DASHBOARD,
  FLOT_CHART,
  MORRIS_CHART,
  CHART_JS,
  PROFILE_PAGE,
  ADMIN_LOGIN,
  ADMIN_FORGET_PASSWORD,
  USER_CONTACT,

  GAME,
  FAQ,
  MANAGE_USER,
  EDIT_MANAGE_USER,
  CATEGORY,
  PLAY_GAME,
  GAME_CATEGORY,
  SOCIAL,
  BANNER,
  PAGE_NOT_FOUND,

  AUTH_USER,
  AUTH_LOGIN,
  AUTH_OTP,
  AUTH_SIGNIN
};

export default ROUTES;
