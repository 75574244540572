import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { addFAQApi } from '../../../../APIs/AdminAll_api';

const AddFAQModal = ({ SetAddFaqModalStatus, getAllFAQ, id }) => {

  const [loading, setLoading] = useState(false)
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')

  const handleAddFAQ = async () => {
    setLoading(true)

    const body = {
      game_id: id,
      question: question,
      answer: answer
    }

    if (question === '') {
      toast.error("Please enter question")
      setLoading(false)
    } else if (answer === '') {
      toast.error("Please enter answer")
      setLoading(false)
    } else {
      const data = await addFAQApi(body)
      if (data.status === 200) {
        setLoading(false)
        SetAddFaqModalStatus(false)
        getAllFAQ()
        toast.success("FAQ Added Successfully");
      } else {
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Add FAQ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              Question
              <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              type='text'
              as='textarea'
              placeholder='Question'
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              Answer
              <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              type='text'
              as='textarea'
              placeholder='Answer'
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { SetAddFaqModalStatus(false) }}>
          Close
        </Button>
        <Button variant="primary" onClick={() => handleAddFAQ()}>
          {
            loading ?
              <div
                class="spinner-border spinner-border-sm me-2"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div> : "Add FAQ"
          }
        </Button>
      </Modal.Footer>
    </>
  )
}

export default AddFAQModal