import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { deteleFAQApi } from '../../../../APIs/AdminAll_api';

const DeleteFAQ = ({ SetDeleteFaqModalStatus, getAllFAQ, faqId }) => {

  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    const data = await deteleFAQApi(faqId);
    if (data.status === 200) {
      setLoading(false)
      SetDeleteFaqModalStatus(false)
      getAllFAQ()
      toast.success(data.data.message);
    } else {
      setLoading(false)
      toast.error(data.response.data.message);
    }
  }

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title><h5 className="confirmTitle">Confirmation Alert!</h5></Modal.Title>
      </Modal.Header>
      <Form className='adminForm' onSubmit={handleDelete}>
        <Modal.Body>
          <p className='mb-2'><span>Are you sure to remove this FAQ?</span></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='modalNo' onClick={() => { SetDeleteFaqModalStatus(false) }}>No</Button>
          <Button variant="primary" className='modalYes' onClick={() => handleDelete()}>{loading ? <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div> : "Yes"}</Button>
        </Modal.Footer>
      </Form>
    </div>
  )
}

export default DeleteFAQ