
import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Col,
  Row,
  Card,
  Form,
  InputGroup,
} from "react-bootstrap";
import { createGameApi } from "../../../APIs/AdminAll_api";
import { getAllcategoryApi } from "../../../APIs/UserAll_api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";
import ReactQuill from "react-quill";
import Select from 'react-select'

const AddGame = () => {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [uploadZip, setUploadZip] = useState("");
  const [video_file, setVideo_File] = useState("");
  const [status, setStatus] = useState(false);
  const [allCategory, setAllCategory] = useState([]);
  const [categoryOPT, setCategoryOPT] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadAsset, setUploadAsset] = useState("");
  const [rating, setRating] = useState("");
  const [developer, setDeveloper] = useState("");
  const [released, setReleased] = useState("");
  const [technology, setTechnology] = useState("");
  const [platform, setPlatform] = useState("");
  const [selectedClassification, setSelectedClassification] = useState([]);
  const [moreInfo, setMoreInfo] = useState("");
  const [howToPlay, setHowToPlay] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [platformDescribe, setPlatformDescribe] = useState("");
  const [controls, setControls] = useState("");
  const [tags, setTags] = useState([])

  const handleSubmit = async (e) => {
    e.preventDefault();

    const re = /^[a-zA-Z0-9 ]+$/;
    const re1 = /^[0-9 ]+$/;
    const imageExt = image.name?.split(".").pop();
    const videoExt = video_file.name?.split(".").pop();
    const uploadZipExt = uploadZip.name?.split(".").pop();
    const uploadAssetExt = uploadAsset.name?.split(".").pop();

    // const allClassification = selectedClassification.map((item) => {
    //   return {
    //     item.value
    //   }
    // })

    if (name === "") {
      setLoading(false);
      toast.error("Please enter game name");
    } else if (name.trim() !== "" && (re1.test(name) || !re.test(name))) {
      setLoading(false);
      toast.error("Please enter a valid game name");
    } else if (tags.some((tag) => tag.trim() === "")) {
      toast.error("All tag fields must be filled!")
      setLoading(false);
    } else if (
      image?.name &&
      imageExt !== "jpg" &&
      imageExt !== "jpeg" &&
      imageExt !== "png" &&
      imageExt !== "svg"
    ) {
      setLoading(false);
      toast.error("Please upload valid image");
    }
    // else if (
    //   uploadZip?.name &&
    //   uploadZipExt !== "zip" &&
    //   uploadZipExt !== "rar" &&
    //   uploadZipExt !== "7zip" &&
    //   uploadZipExt !== "rar4"
    // ) {
    //   setLoading(false);
    //   toast.error("Please upload valid zip file");
    // } 
    else if (
      video_file &&
      videoExt !== "mp4" &&
      videoExt !== "mkv" &&
      videoExt !== "avi" &&
      videoExt !== "mov" &&
      videoExt !== "wmv"
    ) {
      setLoading(false);
      toast.error("Please upload valid video file");
    }
    else if (
      uploadAsset?.name &&
      uploadAssetExt !== "zip" &&
      uploadAssetExt !== "rar" &&
      uploadAssetExt !== "7zip" &&
      uploadAssetExt !== "rar4"
    ) {
      setLoading(false);
      toast.error("Please upload valid zip file");
    }
    else {
      const formData = new FormData();
      if (name) {
        formData.append("name", name);
      }
      if (category) {
        formData.append("category_id", category);
      }
      if (description) {
        formData.append("description", "");
      }
      formData.append("status", status);
      if (image?.name) {
        formData.append("image", image);
      }
      if (video_file?.name) {
        formData.append("video_file", video_file);
      }
      if (uploadZip?.name) {
        formData.append("webgl_zip", uploadZip);
      }
      if (uploadAsset?.name) {
        formData.append("asset_zip", uploadAsset);
      }
      if (rating) {
        formData.append("rating", rating);
      }
      if (developer) {
        formData.append("developer", developer);
      }
      if (released) {
        formData.append("released", released);
      }
      if (technology) {
        formData.append("technology", technology);
      }
      if (platform) {
        formData.append("platform", platform);
      }
      if (selectedClassification) {
        selectedClassification.map((item, index) => {
          formData.append(`classification[${index}]`, item.value)
        })
      }
      if (moreInfo) {
        formData.append("more_info", moreInfo);
      }
      if (howToPlay) {
        formData.append("how_to_play", howToPlay);
      }
      if (releaseDate) {
        formData.append("release_date", releaseDate);
      }
      if (platformDescribe) {
        formData.append("platform_describe", platformDescribe);
      }
      if (controls) {
        formData.append("controls", controls);
      }
      if (tags) {
        tags.map((tag, i) => {
          formData.append(`tags[${i}]`, tag)
        })
      }
      setLoading(true);

      const data = await createGameApi(formData);
      if (data.status === 200) {
        setLoading(false);
        navigate(ROUTES.GAME.LIST);
        toast.success("Game Added Successfully");
      } else {
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
  };

  const getAllCategory = async () => {
    const data = await getAllcategoryApi();
    if (data.status === 200) {
      setAllCategory(data.data.data);

      const option = data.data.data.map((item) => ({
        value: item._id,
        label: item.name
      }))

      setCategoryOPT(option)
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const handleStatusChange = (event) => {
    setStatus(event.target.value === "true");
  };

  var modules = {
    toolbar: [
      ['bold'],
      [{ list: "ordered" }, { list: 'bullet' }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { align: [] }
      ]
    ]
  }

  const formats = ["header", "height", "bold", "list", "bullet", "indent", "align", "size"]

  const handleChangeOption = (selectedOption) => {
    setSelectedClassification(selectedOption)
  }

  const addTag = () => {
    setTags([...tags, ""])
  }

  const removeTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleTagChange = (index, value) => {
    const newTags = [...tags]
    newTags[index] = value;
    setTags(newTags);
  }

  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Game</h4>
            <span className="text-muted1 mt-1 ms-2 mb-0">/ Add Game</span>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <Card className="box-shadow-0">
            <Card.Body>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Name
                  </Form.Label><label className="text-danger">*</label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    id="inputEmail"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Category
                  </Form.Label>
                  <Form.Select

                    className="filter-card2"
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                    name="categoty"
                    value={category}
                  >
                    <option value="" disabled selected>
                      Select Category
                    </option>
                    {allCategory.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Upload Game Image (512*512)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                    }}
                  />
                </Form.Group>
                {/* <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Upload Game Zip
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setUploadZip(e.target.files[0]);
                    }}
                    accept=".zip,.rar,.7zip,.rar4"

                  />
                </Form.Group> */}
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Upload Video</Form.Label> (512*512)
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setVideo_File(e.target.files[0]);
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Upload Game Asset
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setUploadAsset(e.target.files[0]);
                    }}
                    accept=".zip,.rar,.7zip,.rar4"

                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label style={{ color: "#031b4e", fontSize: "14px" }}>
                    Status
                  </Form.Label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Form.Check
                      type="radio"
                      label="Active"
                      name="status"
                      value="true"
                      checked={status}
                      onChange={handleStatusChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Inactive"
                      name="status"
                      value="false"
                      checked={!status}
                      onChange={handleStatusChange}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Row className="px-3">
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Rating
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Rating"
                      onChange={(e) => {
                        setRating(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Developer
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Developer"
                      onChange={(e) => {
                        setDeveloper(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Released
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Released"
                      onChange={(e) => {
                        setReleased(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Technology
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Technology"
                      onChange={(e) => {
                        setTechnology(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Platform
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Platform"
                      onChange={(e) => {
                        setPlatform(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Classification
                    </Form.Label>
                    <Select
                      value={selectedClassification}
                      options={categoryOPT}
                      onChange={(selectedOption) => handleChangeOption(selectedOption)}
                      isMulti
                    />
                  </Form.Group>
                </Col>

                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Description
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      as='textarea'
                      onChange={(e) => {
                        setMoreInfo(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      How to play
                    </Form.Label>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      placeholder="write your content ...."
                      onChange={(event) => setHowToPlay(event)}
                      key="quill-editor"
                    />
                  </Form.Group>
                </Col>

                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Release Date
                    </Form.Label>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      placeholder="write your content ...."
                      onChange={(event) => setReleaseDate(event)}
                      key="quill-editor"
                    />
                  </Form.Group>
                </Col>

                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Platform
                    </Form.Label>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      placeholder="write your content ...."
                      onChange={(event) => setPlatformDescribe(event)}
                    />
                  </Form.Group>
                </Col>

                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Controls
                    </Form.Label>
                    <Form.Control
                      type="text"
                      as='textarea'
                      placeholder="Controls"
                      onChange={(e) => {
                        setControls(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Tags
                    </Form.Label>
                    <div>
                      <Button onClick={addTag}>Add Tags</Button>
                    </div>

                    <div className="row">
                      <div className="col-xxl-6 col-lg-4">
                        {
                          tags.map((tag, i) => (
                            <div key={i} className="d-flex align-items-center mt-2">
                              <Form.Control
                                type="text"
                                value={tag}
                                onChange={(e) => handleTagChange(i, e.target.value)}
                                placeholder="Enter tag"
                                className="me-2"
                              />
                              <Button variant="danger" onClick={() => removeTag(i)}>
                                Remove
                              </Button>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <div>
                <Button type="submit" className="editBtn">
                  {loading ? (
                    <div
                      class="spinner-border spinner-border-sm me-2"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    ""
                  )}
                  Submit
                </Button>
                <Button
                  className="cancelBtn"
                  onClick={() => {
                    navigate(ROUTES.GAME.LIST);
                  }}
                >
                  Cancel
                </Button>
              </div>

            </Card.Body>
          </Card>
        </Form>
      </Row>
    </div>
  );
};

export default AddGame;
