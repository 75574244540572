import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import CustomModal from '../../../../Configs/CustomModal/CustomModal'
import AddFAQModal from './AddFAQModal'
import { getAllFAQApi } from '../../../../APIs/AdminAll_api'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CardBody } from 'reactstrap'
import EditFAQ from './EditFAQ'
import DeleteFAQ from './DeleteFAQ'
import ROUTES from '../../../../Configs/Routes'

const FAQ = () => {

  const [loading, setLoading] = useState(true);
  const [addFaqModalStatus, SetAddFaqModalStatus] = useState(false)
  const [editFaqModalStatus, SetEditFaqModalStatus] = useState(false)
  const [deleteFaqModalStatus, SetDeleteFaqModalStatus] = useState(false)
  const [allFAQ, setAllFAQ] = useState('')
  const [faqId, setFaqId] = useState('')
  const { id } = useParams()
  const navigate = useNavigate()

  const getAllFAQ = async () => {
    setLoading(true);
    const data = await getAllFAQApi(id);
    if (data.status === 200) {
      setLoading(false);
      setAllFAQ(data.data.data)
    }
  }

  useEffect(() => {
    getAllFAQ()
  }, [])

  const handleAddFAQ = () => {
    SetAddFaqModalStatus(true)
  }

  const handleClickEdit = (item) => {
    SetEditFaqModalStatus(true)
    setFaqId(item)
  }

  const handleDelete = (id) => {
    SetDeleteFaqModalStatus(true)
    setFaqId(id)
  }

  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">FAQ</h4>
          </div>
        </div>
        <div className="d-flex my-xl-auto right-content align-items-center">
          <div className="pe-1 mb-xl-0 ms-3">
            <Button
              type="button"
              className="btn add-btn"
              onClick={handleAddFAQ}
            >
              <i className="fa-regular fa-plus m-1"></i>
              Add FAQ
            </Button>
          </div>
          <div className="pe-1 mb-xl-0 ms-2">
            <Button
              type="button"
              className="btn add-btn"
              onClick={() => { navigate(ROUTES.GAME.LIST)}}
            >
              <i class="fa-solid fa-angle-left me-2"></i>
              Back
            </Button>
          </div>
        </div>
      </div>

      <Row className='row-sm'>
        <Col md={12}>
          <Card>
            <Card.Body>
              <div className="">
                <Table
                  responsive
                  className="table card-table table-hover table-vcenter text-nowrap mb-3"
                >
                  <thead className="">
                    <tr className="mb-3 head-color">
                      <th className='text-start'>Question</th>
                      <th>Answer</th>
                      <th className='text-end'>
                        <span>Action</span>
                      </th>
                    </tr>
                  </thead>
                  {
                    !loading &&
                      allFAQ.length > 0 ?
                      allFAQ.map((item, index) => {
                        return (
                          <>
                            <tbody key={index}>
                              <tr>
                                <td>{item.question}</td>
                                <td>{item.answer}</td>
                                <td className='text-end'>
                                  <Link
                                    class="btn btn-sm btn-info btn-b"
                                    onClick={() => handleClickEdit(item._id)}
                                  >
                                    <i class="las la-pen"></i>
                                  </Link>
                                  <Link
                                    class="btn btn-sm btn-danger ms-2"
                                    onClick={() => handleDelete(item._id)}
                                  >
                                    <i class="las la-trash"></i>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        )
                      }) :
                      <tr>
                        <td colSpan={12} className='text-center'>
                          {
                            loading ?
                              <div className='spinner-border text-primary' role="status">
                                <span className="sr-only">Loading...</span>
                              </div> : <p>No data found</p>
                          }
                        </td>
                      </tr>
                  }
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <CustomModal
        display={addFaqModalStatus}
        handleClose={() => SetAddFaqModalStatus(false)}
        className="userModal"
        size='lg'
      >
        <AddFAQModal
          SetAddFaqModalStatus={SetAddFaqModalStatus}
          getAllFAQ={getAllFAQ}
          id={id}
        />
      </CustomModal>

      <CustomModal
        display={editFaqModalStatus}
        handleClose={() => SetEditFaqModalStatus(false)}
        className='userModal'
        size='lg'
      >
        <EditFAQ
          SetEditFaqModalStatus={SetEditFaqModalStatus}
          getAllFAQ={getAllFAQ}
          faqId={faqId}
        />
      </CustomModal>

      <CustomModal
        display={deleteFaqModalStatus}
        handleClose={() => SetDeleteFaqModalStatus(false)}
        className='userModal'
      >
        <DeleteFAQ
          SetDeleteFaqModalStatus={SetDeleteFaqModalStatus}
          getAllFAQ={getAllFAQ}
          faqId={faqId}
        />
      </CustomModal>
    </div>
  )
}

export default FAQ