import React, { useState, useEffect, useRef } from "react";
import UesrLogo from "../../assets/Images/User/UserLogo.png";
import { useNavigate, Link, useParams } from "react-router-dom";
import ROUTES from "../../Configs/Routes";
import { TrackSearch, UserAllDataApi, UserDelete, searchGameApi } from "../../APIs/UserAll_api";
import { Form, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import MainContext from "../Context/MainContext";
import { useContext } from "react";
import { ENV_CONSTANTS } from "../../Configs/Constants";
import { Button } from "reactstrap";
import UserProfile from "../UserProfile";
import UserEditProfile from "../UserEditProfile";
import UserIcon from "../../assets/Images/download.jpg"
import { initOTPless } from "../../Utils/initOtpless";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const timerRef = useRef(null);

  useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};

const Navbar = ({ sidebar, setSidebar }) => {

  const context = useContext(MainContext);

  const { id } = useParams();
  const navigate = useNavigate();
  const [game, setGame] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [userName, setUserName] = useState("")
  const [userEmail, setUserEmail] = useState("")

  const [email, setEmail] = useState()



  const filterButton = document.getElementById("filterButton");
  let isDown = false;
  let startX;
  let scrollLeft;

  if (filterButton) {
    filterButton.addEventListener("mousedown", (e) => {
      isDown = true;
      filterButton.classList.add("active");
      startX = e.pageX - filterButton.offsetLeft;
      scrollLeft = filterButton.scrollLeft;
    });
    filterButton.addEventListener("mouseleave", () => {
      isDown = false;
      filterButton.classList.remove("active");
    });
    filterButton.addEventListener("mouseup", () => {
      isDown = false;
      filterButton.classList.remove("active");
    });
    filterButton.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - filterButton.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      filterButton.scrollLeft = scrollLeft - walk;
    });
  }

  const handleSidebar = () => {
    setSidebar(!sidebar);
  };

  const debouncedSearchText = useDebounce(searchText, 300);
  const searchApi = async (searchValue) => {
    try {
      setLoading(true);
      const res = await searchGameApi(searchValue);
      if (res.status === 200) {
        setLoading(false);
        setGame(res.data.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchText) {
      searchApi(debouncedSearchText);
    }
  }, [debouncedSearchText]);

  const handleSearchInput = (event) => {
    const inputText = event.target.value;
    setSearchText(inputText);
  };

  const getAllData = async () => {
    const respData = await UserAllDataApi(id);
    if (respData.status === 200) {
      context.setAdminData(respData.data?.data);
    }
    else {
      toast.error(respData.response.data.message)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("gameUserToken");
    if (token) {
      getAllData();
    }
  }, [context?.admDataChange])


  const isLoggedIn = !!localStorage.getItem("gameUserToken");

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem('gameUserToken');
    navigate(ROUTES.USER);
  }

  const handleDelete = async () => {
    const respData = await UserDelete()
    if (respData.status === 200) {
      context.setAdmDataChange(!context.admDataChange)
      localStorage.removeItem('gameUserToken');
      toast.success(respData.data.message)
    }
    else {
      toast.error(respData.response.data.message)
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsValue = urlParams.get("ex");
    if (paramsValue) {
      openModal();
    }
  }, []);

  const openModal = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsValue = urlParams.get("ex");
    if (!paramsValue) {
      const currentURL = window.location.href;
      const newParam1 = "ex=true";
      const updatedURL = `${currentURL}?${newParam1}`;
      window.history.pushState(null, "", updatedURL);
    }
    initOTPless();
    const modalContainer = document.getElementById("modalContainer");
    const modal = document.getElementById("otpless-login-page");
    modalContainer.style.display = "flex";
    modal.style.display = modal.style.display === "block" ? "none" : "block";
  };

  const closeModal = (e) => {
    const currentURL = window.location.href;
    const urlWithoutParams = currentURL.split("?")[0];
    window.history.pushState(null, "", urlWithoutParams);
    const modalContainer = document.getElementById("modalContainer");
    if (e.target === modalContainer) {
      modalContainer.style.display = "none";
    }
  };

  const handleNavigateGame = async (id, slug) => {

    // context.setGameName(name)
    // const decodedParam = decodeURIComponent(name);
    // const mergedParam = decodedParam.split(' ').join('').toLowerCase();

    navigate(`${ROUTES.PLAY_GAME.LIST}/${slug}`)
    setSearchText("");
    if (localStorage.getItem("gameUserToken")) {
      const body = {
        game_id: id
      }
      await TrackSearch(body)
    }
  }

  return (
    <>
      <nav className="d-flex flex-wrap align-items-center">
        <div className="d-flex align-items-center">
          <i
            class="fa-solid fa-bars sidebar-toggle-btn me-1"
            onClick={handleSidebar}
          ></i>
          <Link to={ROUTES.USER}>
            <img
              src={UesrLogo}
              alt=""
              className="user-logo me-3"
            // onClick={() => {
            //   navigate(ROUTES.USER);
            // }}
            />
          </Link>
        </div>

        {/* <div className="search-form d-flex "> */}
        <div className="search-form d-flex ms-auto">
          <Dropdown className="search-dropdown shown w-100">
            <Dropdown.Toggle className="bg-transparent border-0 dropdown-btn">
              <div className="position-relative">
                <button className="inputSearchIcon">
                  <i class="fa-solid fa-magnifying-glass text-white"></i>
                </button>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  className="home-search"
                  onChange={handleSearchInput}
                  value={searchText}
                ></Form.Control>
              </div>
            </Dropdown.Toggle>
            {debouncedSearchText && (
              <Dropdown.Menu className="game-list show">
                {loading ? (
                  <div class="d-flex justify-content-center text-white">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : game.length === 0 ? (
                  <p className="not-fond">Game not found</p>
                ) : (
                  game.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        className="list-searchgame"
                        // onClick={() => {
                        //   navigate(`${ROUTES.PLAY_GAME.LIST}/${item._id}`);
                        //   setSearchText("");
                        // }}

                        onClick={() => handleNavigateGame(item?._id, item?.slug)}
                      >
                        <img src={item?.image} alt="" />
                        <span>{item?.name}</span>
                      </Dropdown.Item>
                    );
                  })
                )}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
        <div className="games d-flex align-items-center">
          {/* <i class="fa-regular fa-heart me-2"></i>
          <span className="d-none d-sm-inline me-2">My Games</span> */}

          {isLoggedIn ? (
            <div
              className="main-user"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
            >
              <i class="fa-regular fa-user main-user me-3"></i>
            </div>
          ) : (
            <button
              className="mx-3 game-btn"
              onClick={() => navigate(ROUTES.OTPLESS)}
            // onClick={openModal}
            >
              Log in
            </button>
          )}
          {/* <div id="otpless-login-page"></div> */}
        </div>
      </nav>

      <div class="offcanvas offcanvas-end rightCanvas" tabindex="-1" id="offcanvasRight" >
        <div class="offcanvas-header">
          <div type="button" data-bs-dismiss="offcanvas">
            <span className="closeIcon"><i class="fa-solid fa-xmark"></i></span>
          </div>
        </div>

        <div class="offcanvas-body" style={{ color: "rgb(249, 250, 255)" }}>

          <span className='dashUserIcon me-2 mx-3'>
            <img src={context.adminData?.image ? context.adminData?.image : UserIcon} alt='image' />
          </span>

          <div className="mx-3 mt-2" style={{ lineHeight: "8px" }}>
            <h4 className="text-light fw-bolder">{userName ? userName : context.adminData?.username}</h4>
            <span>{context.adminData?.email}</span>
          </div>

          <div className="">
            <div className="mt-4 d-flex mainUser">
              <span className="profileUser"><i class="fa-regular fa-user me-4 mx-3"></i></span>
              <Link className="text-light text-decoration-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight2">My Profile</Link>
            </div>

            <div className=" mainUser">
              <span className="profileUser"><i class="fa-regular fa-pen-to-square me-4 mx-3"></i></span>
              <Link className="text-light text-decoration-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight3">Edit Profile</Link>
            </div>

            <div className=" mainUser">
              <span className="profileUser"><i class="fa-solid fa-arrow-right-from-bracket me-4 mx-3"></i></span>
              <Link className="text-light text-decoration-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" to={ROUTES.USER} onClick={handleLogout} >Logout</Link>
            </div>

            {/* <div className=" mainUser">
              <span className="profileUser"><i class="fa-regular fa-trash-can me-4 mx-3"></i></span>
              <Link className="text-light text-decoration-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" onClick={handleDelete}>Delete Account</Link>
            </div> */}
          </div>
          <div class="modal-container1" id="modalContainer1" onClick={closeModal}>
            <div class="modal">
              <div id="otpless-login-page"></div>
            </div>
          </div>

        </div>
      </div>



      {/* my profile start */}
      <UserProfile />
      {/* my profile end */}


      {/* edit page start */}
      <UserEditProfile />
      {/* edit page end */}




    </>
  );
};

export default Navbar;